@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .dataset {
    @apply bg-gray-50 shadow-lg text-gray-700 rounded-lg p-8 mx-8 mt-8 
  }
  .dataset-heading {
    @apply text-indigo-500 font-bold text-2xl max-w-2xl mt-8 py-4
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
